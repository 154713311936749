<template>
    <b-row style="height: calc(100vh - 500px); position: relative">
        <b-spinner label="Loading..." v-if="this.editorLoading" style="width: 20px; height: 20px; color: #aaa; position:absolute; z-index:9999; top: 10px; right: 10px;" />
        <b-col cols="2" style="padding: 2px; max-height: calc(100vh - 500px); overflow-y: auto; background-color: var(--sidebar-background-color) !important">
            <TreeView id="operators" :item="operators" :itemSelect="onSelectOperator" :multiSelect="false" style="height: calc(100vh - 500px)" />
        </b-col>
        <b-col cols="8" style="padding: 2px">
            <div :id="idEditor" style="height: calc(100vh - 500px)" />
        </b-col>
        <b-col cols="2" style="padding: 2px; max-height: calc(100vh - 500px); overflow-y: auto; background-color: var(--sidebar-background-color) !important">
            <DxTreeView ref="treeViewEquipmentsAction" class="conf-treeview" :create-children="createChildren" :root-value="''" :expand-nodes-recursive="false" data-structure="plain" item-template="item-template" @item-click="onSelectVariable_cca">
                <template #item-template="item">
                    <div><font-awesome-icon v-if="item.data.icon" :icon="item.data.icon" :color="item.data.iconColor" fixed-width /> {{ item.data.text }}</div>
                </template>
            </DxTreeView>
            <!-- <TreeView id="variables" :item="variables" :itemSelect="onSelectVariable" :multiSelect="false" /> -->
        </b-col>
    </b-row>
</template>

<script>
import DxTreeView from 'devextreme-vue/tree-view';
import ToastAlert from '@/utils/ToastAlert';
import loader from '@monaco-editor/loader';
import TreeView from '@/components/TreeView.vue';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import { uuid } from 'vue-uuid';
export default {
    name: 'CodeEditor',
    props: {
        /**
         * Current project's Guid
         */
        propProject: String,
        /**
         * Initial value in the editor.
         *
         */
        Formula: String,
    },
    components: {
        ToastAlert,
        DxTreeView,
        TreeView,
    },
    watch: {
        Formula: {
            handler: function (val, oldVal) {
                if (this.editor != null) {
                    this.editor.setValue(val);
                }
            },
        },
    },
    data() {
        return {
            idEditor: `monaco-editor-${uuid.v4()}`, // Generate a unique ID to avoid conflicts when mounting the editor.
            editor: null,
            editorLoading: true,
            isCancelled: false,
            monaco: null,
            completionProvider: null,
            variables: [],
            operators: [
                { id: '+', text: '+', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '-', text: '-', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '*', text: '*', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '/', text: '/', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '%', text: '%', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '^', text: '^', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '>', text: '>', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '<', text: '<', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '=>', text: '=>', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '<=', text: '<=', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '==', text: '==', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '<>', text: '<>', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '&', text: '&', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '|', text: '|', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '!&', text: '!&', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: '~', text: '~', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'AND', text: 'AND', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'OR', text: 'OR', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'NOT', text: 'NOT', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'NOW()', text: 'NOW()', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'IIf(,,)', text: 'IIF()', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'ABS()', text: 'ABS()', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'RANDOM(,)', text: 'RANDOM()', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'FORMAT(,)', text: 'FORMAT()', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'PADLEFT(,,"0")', text: 'PADLEFT()', expanded: false, items: null, contextMenuSelected: false, selected: false },
                { id: 'TOSTRING()', text: 'TOSTRING()', expanded: false, items: null, contextMenuSelected: false, selected: false },
            ],
            equipments: [],
        };
    },
    async mounted() {
        //initialisation of the monaco framework
        this.monaco = await loader.init();
        this.monaco.languages.register({ id: 'specificlang' });
        // Define a new theme that constains only rules that match this language
        this.monaco.editor.defineTheme('myCoolTheme', {
            base: 'vs',
            inherit: false,
            colors: {
                'editor.foreground': '#F8F8F2',
                'editor.background': '#282C34',
                'editor.selectionBackground': '#3E4451',
                'editor.lineHighlightBackground': '#2C313A',
                'editorCursor.foreground': '#F8F8F0',
                'editorWhitespace.foreground': '#3B4048',

                // Couleurs du texte
                'editorComment.foreground': '#6A9955',
                'editorComment.fontStyle': 'italic',
                'editorString.foreground': '#CE9178',
                'editorString.fontStyle': 'bold',
                'editorKeyword.foreground': '#C586C0',
                'editorKeyword.fontStyle': 'bold',
                'editorKeyword.opacity': '0.7',
                'editorKeywordUnderline.foreground': '#569CD6',
                'editorOperator.foreground': '#C586C0',
                'editorOperator.fontStyle': 'bold',
                'editorOperator.opacity': '0.7',
                'editorType.foreground': '#4EC9B0',
                'editorType.fontStyle': 'bold',
                'editorType.opacity': '0.7',
                'editorIdentifier.foreground': '#E9C062',
                'editorIdentifier.fontStyle': 'normal',
                'editorFunction.foreground': '#DCDCAA',
                'editorFunction.fontStyle': 'bold',
                'editorVariable.foreground': '#9CDCFE',
                'editorVariable.opacity': '0.7',
                'editorVariable.fontStyle': 'normal',
                'editorConstant.foreground': '#4FC1FF',
                'editorConstant.opacity': '0.7',
                'editorConstant.fontStyle': 'normal',
                'editorInvalid.underline': '#FF0000',

                // Couleurs des marqueurs de la barre latérale
                'editorMarkerNavigation.background': '#3B3B3B',
                'editorMarkerNavigationError.background': '#FF0000',
                'editorMarkerNavigationWarning.background': '#FFA500',

                // Couleurs des numéros de ligne
                'editorLineNumber.foreground': '#5B6370',
                'editorLineNumber.activeForeground': '#D7DAE0',
                'editorLineNumber.background': '#282C34',
                'editorLineNumber.activeBackground': '#282C34',
            },
            rules: [
                { token: 'custom-var', foreground: '04aeef', fontStyle: 'bold' },
                { token: 'operator', foreground: '#bbbbbb' },
                { token: 'binaryOperator', foreground: '#00cc00' },
                { token: 'function', foreground: '#04aeef' },
            ],
        });

        this.$nextTick(() => {
            this.createEditor();
        });
    },
    beforeDestroy() {
        // Make sure to correctly dispose of the context, to avoid memory / performance problems.
        this.monaco?.editor?.getModels()?.forEach((model) => model.dispose());
        if (this.completionProvider != null) this.completionProvider.dispose();
        this.editor?.dispose();
    },
    methods: {
        /**
         * Get the list of equipments and then the list of variables ...
         */
        async createChildren(parent) {
            const parentId = parent ? parent.key : '';
            if (parentId === '') {
                // Get the list of equipments
                const equipments = await ProdComEquipmentService.getAllEquipments(this.propProject, false);
                if (equipments.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(equipments.ret, equipments.retParams), 'warning'));
                    return [];
                }

                this.equipments = equipments.ret;

                // Initialize the editor
                this.$nextTick(() => {
                    setTimeout(async () => {
                        this.initCodeEditor(equipments.ret);
                    }, 2000);
                });

                return equipments.ret.map((equipment) => {
                    return {
                        id: `${equipment.Name}`,
                        internalId: equipment.Name,
                        itemType: 'equipment',
                        icon: equipment.Active ? 'fas fa-container-storage fa-fw' : 'fal fa-container-storage fa-fw',
                        iconColor: equipment.Active ? '#8dc149' : '#dadada',
                        parentId: null,
                        text: equipment.Name,
                        expanded: equipments.length == 1,
                        hasItems: true,
                        varsCount: equipment.VariableCount,
                        ProdComVarsCount: equipment.ProdComVariableCount,
                        actionsCount: equipment.ActionCount,
                        datasourceName: equipment.DataSourceName,
                        active: equipment.Active,
                    };
                });
            } else if (parent.itemData.itemType == 'equipment') {
                // Get the list of variables
                const variables = await ProdComEquipmentService.getEquipmentVariables(parent.itemData.internalId, this.propProject);
                if (variables.success == 'n') {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(variables.ret, variables.retParams), 'warning'));
                    return [];
                }
                var variablesList = [];
                var variablesGroups = [];
                var variablesAttributes = [];
                variables.ret.List.forEach((variable) => {
                    var variableGroup = variablesGroups.find((group) => group.internalId == variable.Group);
                    if (!variableGroup) {
                        variablesGroups.push({
                            id: `${parent.key}¤¤${variable.Group}`,
                            internalId: variable.Group,
                            itemType: 'variable-group',
                            icon: '',
                            iconColor: '',
                            parentId: parent.key,
                            text: `${variable.Group} (1)`,
                            expanded: false,
                            hasItems: true,
                            variablesCount: 1,
                        });
                    } else {
                        variableGroup.variablesCount++;
                        variableGroup.text = `${variableGroup.internalId} (${variableGroup.variablesCount})`;
                    }
                    let temp_variable = `${parent.itemData.internalId}_${variable.Group}_${variable.Name}`;
                    variablesList.push({
                        id: `${temp_variable}`,
                        internalId: variable.Name,
                        itemType: 'variable',
                        icon: '',
                        iconColor: '',
                        parentId: `${parent.key}¤¤${variable.Group}`,
                        text: variable.Name,
                        expanded: false,
                        hasItems: true,
                    });

                    variablesAttributes.push([
                        {
                            id: temp_variable + '_Valeur',
                            parentId: `${temp_variable}`,
                            itemType: 'variable-attribute',
                            text: 'Valeur',
                            expanded: false,
                            items: null,
                            icon: 'fas fa-tag',
                            iconColor: '#f57c00',
                            hasItems: false,
                        },
                        {
                            id: temp_variable + '_Valeur_N1',
                            parentId: `${temp_variable}`,
                            itemType: 'variable-attribute',
                            text: 'Valeur_N1',
                            expanded: false,
                            items: null,
                            icon: 'fas fa-tag',
                            iconColor: '#f57c00',
                            hasItems: false,
                        },
                        {
                            id: temp_variable + '_Max',
                            parentId: `${temp_variable}`,
                            itemType: 'variable-attribute',
                            text: 'Max',
                            expanded: false,
                            items: null,
                            icon: 'fas fa-tag',
                            iconColor: '#f57c00',
                            hasItems: false,
                        },
                        {
                            id: temp_variable + '_Min',
                            parentId: `${temp_variable}`,
                            itemType: 'variable-attribute',
                            text: 'Min',
                            expanded: false,
                            items: null,
                            icon: 'fas fa-tag',
                            iconColor: '#f57c00',
                            hasItems: false,
                        },
                        {
                            id: temp_variable + '_NbVariation',
                            parentId: `${temp_variable}`,
                            itemType: 'variable-attribute',
                            text: 'NbVariation',
                            expanded: false,
                            items: null,
                            icon: 'fas fa-tag',
                            iconColor: '#f57c00',
                            hasItems: false,
                        },
                    ]);
                });
                if (variablesGroups.length == 1) {
                    variablesGroups[0].expanded = true;
                }
                return variablesGroups.concat(variablesList, variablesAttributes.flat());
            }
        },
        /**
         * Select an operator: add it to the current cursor position
         */
        async onSelectOperator(e) {
            // TODO: switch to editor.executeEdit (to allow undo / redo). Later...
            this.editor.trigger('keyboard', 'type', { text: `${e[0].id}` });
        },
        /**
         * Select a variable: correctly format it (EQUIPNAME_GROUP_VARIABLE_VARINTERNE)
         */
        async onSelectVariable_cca(selected) {
            if (selected.itemData.itemType == 'variable-attribute') {
                // TODO: switch to editor.executeEdit (to allow undo / redo). Later...
                this.editor?.trigger('keyboard', 'type', { text: `{${selected?.node.parent?.parent?.parent.itemData.internalId}_${selected?.node.parent?.parent.itemData.internalId}_${selected?.node.parent?.itemData.internalId}_${selected?.itemData.text}}` });
            }
        },
        async onSelectVariable(e) {
            var selected = e[0];
            if (selected?.parent != null && selected?.parent?.parent != null && selected?.parent?.parent != null && selected?.items == null) {
                // TODO: switch to editor.executeEdit (to allow undo / redo). Later...
                this.editor?.trigger('keyboard', 'type', { text: `{${selected?.parent?.parent?.parent.text}_${selected?.parent?.parent.text}_${selected?.parent?.text}_${selected?.text}}` });
            }
        },
        /**
         * Get the current value back from the editor
         */
        getValue() {
            return this.editor?.getValue();
        },
        cancel() {
            this.isCancelled = true;
        },
        createEditor() {
            if (this.editor != null) return;
            // TODO: Register our custom language
            this.editor = this.monaco.editor.create(document.getElementById(this.idEditor), {
                automaticLayout: true,
                theme: 'myCoolTheme', // TODO: auto theme from config
                readOnly: false,
                language: 'specificlang',
                wordWrap: 'wordWrapColumn',
                languageConfiguration: {
                    // Activer la mise en évidence sémantique pour les variables
                    semanticHighlighting: true,
                },
            });
            // Get back value from existing configuration
            this.editor.trigger('keyboard', 'type', { text: this.Formula });
            //Scroll to top
            this.editor.setScrollTop(0);
        },
        async initCodeEditor(equipments) {
            let keywords = [];
            let myArrayTokenRoot = [];
            if (this.$store.state.monacoSettings != null && this.$store.state.monacoSettings[this.propProject]) {
                keywords = this.$store.state.monacoSettings[this.propProject].keywords;
                myArrayTokenRoot = this.$store.state.monacoSettings[this.propProject].tokenRoot;
                // console.log('getting keywords');
            } else {
                for (const equip of equipments) {
                    if (this.isCancelled) {
                        this.editorLoading = false;
                        return;
                    }
                    const dataVar = await ProdComEquipmentService.getEquipmentVariables(equip.Name, this.propProject);
                    if (dataVar.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(dataVar.ret), 'warning'));
                    else {
                        for (const variable of dataVar.ret.List) {
                            let temp_variable = `${equip.Name}_${variable.Group}_${variable.Name}`;
                            myArrayTokenRoot.push(
                                ...[
                                    ['(?<=\\W|^)' + temp_variable + '_Valeur' + '(?=\\W|$)', 'custom-var'],
                                    ['(?<=\\W|^)' + temp_variable + '_Valeur_N1' + '(?=\\W|$)', 'custom-var'],
                                    ['(?<=\\W|^)' + temp_variable + '_Max' + '(?=\\W|$)', 'custom-var'],
                                    ['(?<=\\W|^)' + temp_variable + '_Min' + '(?=\\W|$)', 'custom-var'],
                                    ['(?<=\\W|^)' + temp_variable + '_NbVariation' + '(?=\\W|$)', 'custom-var'],
                                ],
                            );
                            keywords.push('{' + temp_variable + '_Valeur' + '}');
                            keywords.push('{' + temp_variable + '_Valeur_N1' + '}');
                            keywords.push('{' + temp_variable + '_Max' + '}');
                            keywords.push('{' + temp_variable + '_Min' + '}');
                            keywords.push('{' + temp_variable + '_NbVariation' + '}');
                        }
                    }
                }
                myArrayTokenRoot.push([/(ABS(?=\())|(NOW(?=\())|(IIF(?=\())|(RANDOM(?=\())|(FORMAT(?=\())|(PADLEFT(?=\())|(TOSTRING(?=\())|\(|\)/, 'function'], [/(\+|-|\*|\/|%|\^|~)/, 'operator'], [/(>|<|>=|<=|&|\||==|AND|OR|NOT|!&|<>)/, 'binaryOperator']);
                // await this.$store.commit('setMonacoKeywords', keywords);
                // await this.$store.commit('setMonacoArrayTokenRoot', myArrayTokenRoot);

                await this.$store.commit('setMonacoSettings', {
                    projectId: this.propProject,
                    keywords,
                    tokenRoot: myArrayTokenRoot,
                });
            }

            // Add keywords
            this.monaco.languages.setMonarchTokensProvider('specificlang', {
                keywords,
                tokenizer: {
                    root: myArrayTokenRoot,
                },
            });
            // Autocompletion config
            this.completionProvider = this.monaco.languages.registerCompletionItemProvider('specificlang', {
                provideCompletionItems: (model, position) => {
                    const suggestions = [
                        ...keywords.map((k) => {
                            return {
                                label: k,
                                kind: monaco.languages.CompletionItemKind.Keyword,
                                insertText: k,
                            };
                        }),
                    ];
                    return { suggestions: suggestions };
                },
            });
            this.editorLoading = false;
        },
    },
};
</script>
<style lang="scss">
.highlight-blue {
    background-color: blue;
    color: white;
}
</style>